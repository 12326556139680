import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const phoneNumber = '7558793773'; // Replace with your phone number
  const message = 'Hello, I would like to inquire about your services.';
  const instagram= 'https://www.instagram.com/quantumstack/';
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  const facebookPageUrl = 'https://www.facebook.com/quantumstack'; // Replace with your Facebook page URL
  const linkedinProfileUrl = 'https://www.linkedin.com/company/quantum-stack/'; // Replace with your LinkedIn profile URL

  return (
    <footer className="footer">
      <div className="footer-content">
        {/* <img src='logo.png' width={'50%'} /> */}
        <p>&copy; {new Date().getFullYear()} Quantum Stack. All rights reserved.</p>
        <div className="followus">
          <h2>Follow Us</h2>
          <a 
            href={linkedinProfileUrl} 
            className="social-link linkedin-link" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a 
            href={url} 
            className="social-link whatsapp-link" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
          <a 
            href={facebookPageUrl} 
            className="social-link facebook-link" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a 
  href={instagram} 
  className="social-link instagram-link" 
  target="_blank" 
  rel="noopener noreferrer"
>
  <FontAwesomeIcon icon={faInstagram} />
</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
